import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Markdown from "./modules/components/Markdown";
import Typography from "./modules/components/Typography";
import AppFooter from "./modules/views/AppFooter";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-211546357-1";
ReactGA.initialize(TRACKING_ID);

function Privacy() {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    ReactGA.event({
      category: 'Page Load',
      action: 'Privacy Page'
    });
  }, [])

  // https://github.com/webpack/webpack/issues/6680
  useEffect(() => {
    import("./modules/views/privacy.md")
      .then((content) => fetch(content.default))
      .then((response) => response.text())
      .then((responseText) => setMarkdown(responseText));
  });

  if (!markdown) {
    return <div />;
  }

  return (
    <React.Fragment>
      {/*<AppAppBar />*/}
      <Container>
        <Box mt={7} mb={12}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Privacy
          </Typography>
          <Markdown>{markdown}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Privacy);
