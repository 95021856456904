import React from "react";
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";


const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
      marginBottom: 0,
      display: "flex",
    },
    cardWrapper: {
      zIndex: 1,
    },
    card: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.warning.main,
      padding: theme.spacing(8, 3),
    },
    cardContent: {
      maxWidth: 400,
    },
    textField: {
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    button: {
      width: "100%",
    },
    imagesWrapper: {
      position: "relative",
        display: "flex",
        justifyContent: "space-around",
    },
      image: {
          height: "50vh",
          minHeight: "315px",
          width: "auto",
          marginTop: "-10rem",
          marginBottom: "-5rem",
          textAlign: "center"
      },
  });

function ProductCTA(props: WithStyles<typeof styles>) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container className={classes.root} component="section">
      <Grid container>
          {/*<Grid item xs={12} md={6} className={classes.cardWrapper}>*/}
          {/*    <div className={classes.card}>*/}
          {/*        <form onSubmit={handleSubmit} className={classes.cardContent}>*/}
          {/*            <Typography variant="h2" component="h2" gutterBottom>*/}
          {/*                Receive offers*/}
          {/*            </Typography>*/}
          {/*            <Typography variant="h5">*/}
          {/*                Taste the holidays of the everyday close to home.*/}
          {/*            </Typography>*/}
          {/*            <TextField*/}
          {/*                noBorder*/}
          {/*                className={classes.textField}*/}
          {/*                placeholder="Your email"*/}
          {/*            />*/}
          {/*            <Button*/}
          {/*                type="submit"*/}
          {/*                color="primary"*/}
          {/*                variant="contained"*/}
          {/*                className={classes.button}*/}
          {/*            >*/}
          {/*                Keep me updated*/}
          {/*            </Button>*/}
          {/*        </form>*/}
          {/*    </div>*/}
          {/*</Grid>*/}
        <Grid item xs={12} sm={12} md={6} className={classes.imagesWrapper}>
            <img
              src="/iphone-with-screenshot.png"
              alt="call to action"
              className={classes.image}
            />
        </Grid>
          <Hidden smDown >
          <Grid item md={6} className={classes.imagesWrapper}>
              <img
                  src="/iphone-with-screenshot-account.png"
                  alt="call to action"
                  className={classes.image}
              />
          </Grid>
          </Hidden>
      </Grid>
    </Container>
  );
}

export default withStyles(styles)(ProductCTA);
