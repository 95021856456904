import withRoot from "./modules/withRoot";
// --- Post bootstrap ----
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import AppFooter from "./modules/views/AppFooter";
import ReactGA from "react-ga";
import ReactPlayer from "react-player";


const TRACKING_ID = "UA-211546357-1";
ReactGA.initialize(TRACKING_ID);

function Dad() {

  useEffect(() => {
    ReactGA.event({
      category: 'Page Load',
      action: 'Dreyer Video Page'
    });
  }, [])

  return (
      <Container>
        <ReactPlayer playing url='Dad.mp4' controls={true}   width='100%' height='100%'/>
      </Container>
  );
}

export default withRoot(Dad);
