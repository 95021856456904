import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import {RefreshDouble, VoicePhone, Group} from "iconoir-react";
import Button from "../components/Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      backgroundColor: theme.palette.secondary.light,
    },
    container: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(12),
      display: "flex",
      position: "relative",
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5),
    },
    image: {
      height: 55,
    },
    title: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    curvyLines: {
      pointerEvents: "none",
      position: "absolute",
      top: -180,
      display: "none"
    },
    linkStyles: {
      fontSize: "16px",
      textDecoration: "none"
    }
  });

function ProductValues(props: WithStyles<typeof styles>) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <RefreshDouble color={"#4095D1"} width={"5em"} height={"5em"}/>
              <Typography variant="h6" className={classes.title}>
                Sync status and presence
              </Typography>
              <Typography variant="h5">
                {
                  "Create, save, and send custom Slack statuses"
                }
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <VoicePhone color={"#4095D1"} width={"5em"} height={"5em"}/>
              <Typography variant="h6" className={classes.title}>
                From your phone
              </Typography>
              <Typography variant="h5">
                {
                  "Download directly on your iOS or Android device:"
                }
              </Typography><br />
                <div className={'app-images'}>
                    <a
                        href="https://apps.apple.com/us/app/dubslack/id1591330134" target='_blank' >
                        <img
                            src="/appstore.png"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.dubslack" target='_blank'  >
                        <img src="/playstore.png"/>
                    </a>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Group color={"#4095D1"} width={"5em"} height={"5em"}/>
              <Typography variant="h6" className={classes.title}>
                Multiple Accounts
              </Typography>
              <Typography variant="h5">
                {"Connect and update multiple accounts simultaniously"}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(styles)(ProductValues);
