import React from "react";
import "./App.css";
import Home from "./dubslack/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Privacy from "./dubslack/Privacy";
import Dad from "./dubslack/Dad";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {/*<Route path="/forgot-password">*/}
          {/*  <ForgotPassword />*/}
          {/*</Route>*/}
          {/*<Route path="/sign-up">*/}
          {/*  <SignUp />*/}
          {/*</Route>*/}
          {/*<Route path="/sign-in">*/}
          {/*  <SignIn />*/}
          {/*</Route>*/}
          {/*<Route path="/terms">*/}
          {/*  <Terms />*/}
          {/*</Route>*/}
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
          <Route path="/privacy-policy.html">
            <Privacy />
          </Route>
          <Route path={["/dad","/dad.html","/Dad","/Dad.html"]}>
            <Dad />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
