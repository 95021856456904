import React from "react";
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
} from "@material-ui/core/styles";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

//const backgroundImageOrig = "https://images.unsplash.com/photo-1534854638093-bada1813ca19?auto=format&fit=crop&w=1400&q=80";
const backgroundImage = "https://images.unsplash.com/photo-1518837695005-2083093ee35b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2340&q=80";


const styles = (theme: Theme) =>
  createStyles({
    background: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundColor: "#FFF", // Average color of the background image.
      backgroundPosition: "center",
    },
    button: {
      minWidth: 200,
    },
    h2: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(10),
      },
    },
    more: {
      marginTop: theme.spacing(2),
    },
  });

function ProductHero(props: WithStyles<typeof styles>) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" className={classes.h2}>
        Streamline your Slack status
      </Typography>
        <img
            src="/dubslack-logo.png"
            alt="dubslack-logo"
            width="300px"
            height="300px"
        />
      <Typography
        color="inherit"
        align="center"
        variant="h5"
      >
          Manage and sync your Slack status and presence across multiple workspaces simultaneously
      </Typography>
      {/*<Button*/}
      {/*  color="secondary"*/}
      {/*  variant="contained"*/}
      {/*  size="large"*/}
      {/*  className={classes.button}*/}
      {/*  component={RouterLink}*/}
      {/*  to="/sign-up/"*/}
      {/*>*/}
      {/*  Register*/}
      {/*</Button>*/}
      {/*<Typography variant="body2" color="inherit" className={classes.more}>*/}
      {/*  Discover the experience*/}
      {/*</Typography>*/}
    </ProductHeroLayout>
  );
}

export default withStyles(styles)(ProductHero);
