import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React, {useEffect} from "react";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductCTA from "./modules/views/ProductCTA";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-211546357-1";
ReactGA.initialize(TRACKING_ID);

function Index() {

    useEffect(() => {
        ReactGA.event({
            category: 'Page Load',
            action: 'Home Page'
        });
    }, [])

  return (
    <React.Fragment>
      {/*<AppAppBar />*/}
      <ProductHero />
        <ProductCTA />
      <ProductValues />
      {/*<ProductCategories />*/}
      {/*<ProductHowItWorks />*/}
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
