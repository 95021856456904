import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Typography from "../components/Typography";
import { Link as RouterLink } from "react-router-dom";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://dubslack.com/">
        Dubslack
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  centeredColumn: {
    textAlign: "center"
  }
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} md={4}  className={classes.centeredColumn} >
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link component={RouterLink} to="/privacy-policy">
                  Privacy
                </Link>
              </li>
            </ul>
          </Grid>
          <Hidden smDown >
          <Grid item md={4}  className={classes.centeredColumn} style={{marginTop: "-25px"}}>
            <a href={"https://dubslack.com"}><img
                src="/dubslack-logo.png"
                alt="dubslack-logo"
                width="100px"
                height="100px"
            /></a>
            <Grid item>
              <Copyright />
            </Grid>
          </Grid>
        </Hidden>
          <Grid item xs={6} md={4}  className={classes.centeredColumn} >
            <Typography variant="h6" gutterBottom>
              Download
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
            <Link href="https://apps.apple.com/us/app/dubslack/id1591330134" target={"_blank"}>
              iOS
            </Link>
              </li>
              <li className={classes.listItem}>
            <Link href="https://play.google.com/store/apps/details?id=com.dubslack" target={"_blank"}>
              Android
            </Link>
              </li></ul>
          </Grid>
          <Hidden mdUp >
          <Grid item xs={12}  className={classes.centeredColumn}>
            <a href={"https://dubslack.com"}><img
                src="/dubslack-logo.png"
                alt="dubslack-logo"
                width="100px"
                height="100px"
            /></a>
            <Grid item>
              <Copyright />
            </Grid>
          </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Typography>
  );
}
